import { useEffect, useRef, useState } from 'react';
import styles from './Game.module.css';
import makeGame, { GameState } from './makeGame';
import { loadState, throttledSaveState } from './store';


const isDev = import.meta.env['DEV'];


export default function Game({ onGameCompleted, muteMusicFor }: {
  onGameCompleted: () => void,
  muteMusicFor: (d: number) => void,
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const hudRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const animation = containerRef.current!.animate([{ filter: 'contrast(0.5) brightness(3)' }, { filter: '' }], { duration: 1000 });
    return () => animation.cancel();
  }, []);

  useEffect(() => {
    const dblclickHandler = (e: MouseEvent) => e.preventDefault()
    const touchmoveHandler = (e: TouchEvent) => {
      if((e as unknown as { scale: number }).scale !== 1) {
        e.preventDefault();
      }
    };
    const beforeunload = (e: BeforeUnloadEvent) => {
      if (!isDev) e.preventDefault();
    }

    // https://stackoverflow.com/a/71393919/6519037
    document.addEventListener('dblclick', dblclickHandler, { passive: false })
    // https://stackoverflow.com/a/70002058/6519037
    document.addEventListener('touchmove', (touchmoveHandler), { passive: false });
    window.addEventListener('beforeunload', beforeunload);

    return () => {
      document.removeEventListener('dblclick', dblclickHandler);
      document.removeEventListener('touchmove', touchmoveHandler);
      window.removeEventListener('beforeunload', beforeunload);
    };
  }, []);

  const [gameState, setGameState] = useState<GameState>();

  const gameRef = useRef<ReturnType<typeof makeGame>>(undefined)
  useEffect(() => {
    function handleStateChange(state: GameState) {
      setGameState(state);
      const { objects, spaceship, wind, ...rest } = state;
      throttledSaveState({ ...rest });
    }
    const initialState = loadState();

    gameRef.current = makeGame({ gameNode: containerRef.current!, hud: hudRef.current!, styles, initialState, onStateChange: handleStateChange, onGameCompleted, muteMusicFor });
    return () => {
      gameRef.current?.cleanup();
      gameRef.current = undefined;
    };
  }, []);

  return (
    <div ref={containerRef} style={{ width: '100dvw', height: '100dvh', position: 'relative' }} className={`${styles['container']}`}>
      <div style={{position: 'absolute', width: '100%', height: '100%'}}>
        <div id="canvas" style={{position: 'relative', width: '100%', height: '100%', overflow: 'hidden'}} />
      </div>

      <div ref={hudRef} className={styles['hud']} onPointerDown={() => gameRef.current?.triggerBomb()}>
        {gameState && (
          <>
            <span className="bomb">
              <span style={{ display: 'inline-block', animation: gameState.numBombs > 0 ? '1s infinite pulse' : undefined }}>💣</span>{gameState.numBombs}
            </span>
            <span className="star" style={{display: 'inline-block', pointerEvents: 'none'}}>🌟</span>
            <span style={{pointerEvents: 'none'}}>{gameState.numPoints}/{gameState.finishLevelAt}</span>
            <span style={{pointerEvents: 'none'}}>✅{gameState.levelAt}</span>
          </>
        )}
      </div>
    </div>
  )
}
