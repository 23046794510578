import { CSSProperties, UIEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Game from './Game';
import miffySvg from './miffy.svg';
import styles from './App.module.css';

import './index.css';
import { produce } from 'immer';
import makeMusicPlayer from './musicPlayer';
import { resetState } from './store';

const isDev = import.meta.env['DEV'];
const autoStart = isDev;
// const autoStart = false;

function Home({ onStart }: { onStart: () => void }) {
  const bottomRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<{ animation: Animation, timeout: number }>(undefined);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback<UIEventHandler<HTMLDivElement>>((e) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current.timeout);
      timeoutRef.current.animation.cancel();
    }

    if (e.currentTarget.scrollTop >= e.currentTarget.scrollHeight - e.currentTarget.clientHeight - 1) {
      const duration = 2000;
      const animation = wrapperRef.current!.animate([{ filter: '' }, { offset: 0.5, filter: 'contrast(0.5) brightness(3) blur(0)' }, { filter: 'contrast(0.4) brightness(7.5) blur(10vmin)' }], { duration });
      const timeout = setTimeout(() => {
        onStart();
      }, duration);
      timeoutRef.current = { animation, timeout };
    }
  }, []);

  const handleDownClick = useCallback(() => bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' }), []);

  return (
    <>
      <div ref={wrapperRef} style={{textAlign: 'center', overflowY: 'auto', height: '100dvh'}} onScroll={handleScroll}>
        <img role="button" onClick={handleDownClick} src={miffySvg} style={{height: '40vh', marginBottom: '-8vh', animation: 'pulse 2s linear infinite', cursor: 's-resize'}} />

        <div className="column" style={{backgroundColor: '#d06736', height: '30vh'}} />

        <div style={{width: '30vh', margin: 'auto', backgroundColor: '#e8bd2a', color: 'white', padding: '1vh 4vh', borderRadius: '4vh', border: '.8vh solid black', fontFamily: 'sans-serif', letterSpacing: '.6vh', fontWeight: 100, fontSize: '8vh'}}>
          miffy.no
        </div>

        <div className="rainbowish column" style={{height: '200vh'}} />

        <div className="section rainbow" style={{height: '200vh'}}>
          <div className="transparent-gradient1" />
          <div className="transparent-gradient2" />
          <div className="column" style={{height: '100%', backgroundColor: '#d06736'}} />
        </div>

        <div className="section rainbow rainbow-animated1" style={{height: '200vh'}}>
          <div className="transparent-gradient1" />
          <div className="transparent-gradient2" />
          <div className="column" style={{height: '100%', backgroundColor: '#d06736'}} />
        </div>

        <div className="section rainbow rainbow-animated2" style={{height: '200vh'}}>
          <div className="transparent-gradient1" />
          <div className="transparent-gradient2" />
          <div className="column" style={{height: '100%', backgroundColor: '#d06736'}} />
        </div>

        <div ref={bottomRef} style={{background: 'black', padding: '4vh 2vh', color: 'rgba(255,255,255,0.5)'}}>
          Music: Arcadewave by <a target="_blank" rel="noreferrer" href="http://link.epidemicsound.com/LUPUS" style={{color: 'inherit'}}>Lupus Nocte</a>
          <div style={{marginTop: '1em'}}><a style={{textDecoration: 'none', color: 'rgba(255,255,255,0.7)'}} href="https://mifi.no/" target="_blank" rel="noreferrer">mifi.no</a></div>
        </div>

        <div style={{ position: 'absolute', top: '45%', left: '50%', fontSize: '10vh', marginLeft: '-5vh', cursor: 's-resize' }}>
          <span onClick={handleDownClick}>
            ⬇️
          </span>
        </div>
      </div>
    </>
  )
}

const getStartingVelocity = () => (Math.random() * 2 - 1) / 1.5;
const getStartingX = () => 50 + (Math.random() * 6 - 3);
const getStartingY = () => 10 + (Math.random() * 6 - 3);

interface MiffyType { x: number, y: number, dx: number, dy: number, width: number }

function Miffy({ miffy }: { miffy: MiffyType }) {
  const animation = useMemo(() => `${styles['miffy']} 1s ease-in-out ${Math.random() * 0.5}s infinite`, []);
  const style = useMemo<CSSProperties>(() => ({ position: 'absolute', pointerEvents: 'none', marginLeft: `${-miffy.width/2}%`, marginTop: `${-miffy.width/2}%`, width: `${miffy.width}%`, left: `${miffy.x}%`, top: `${miffy.y}%`, animation }), [miffy]);

  return (
    <img src={miffySvg} style={style} />
  )
}

function Completed({ onStartAgainClick }: { onStartAgainClick: () => void }) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [miffys, setMiffys] = useState<MiffyType[]>([]);
  const pointerRef = useRef<{ x: number, y: number, dx: number, dy: number }>(undefined);
  const [mousePos, setMousePos] = useState<{ x: number, y: number }>();
  const [enableInputs, setEnableInputs] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      setEnableInputs(true);
    }, 3000);
    return () => clearTimeout(t);
  }, [])

  useEffect(() => {
    setMiffys(Array.from({ length: 100 }).map(() => ({
      x: getStartingX(),
      y: getStartingY(),
      dx: getStartingVelocity(),
      dy: getStartingVelocity(),
      width: 5 + Math.random() * 5,
    })));

    const raf = () => requestAnimationFrame(() => {
      setMiffys((miffys) => (
        miffys.map(produce((miffy) => {
          if (pointerRef.current) {
            const distance = Math.sqrt(((miffy.x / 100) - pointerRef.current.x) ** 2 + ((miffy.y / 100) - pointerRef.current.y) ** 2);
            let directionX = (miffy.x / 100) - pointerRef.current.x;
            directionX = directionX === 0 ? 0 : directionX / Math.abs(directionX);
            let directionY = (miffy.y / 100) - pointerRef.current.y;
            directionY = directionY === 0 ? 0 : directionY / Math.abs(directionY);
            miffy.dx += directionX * (1 / distance) / 300;
            miffy.dy += directionY * (1 / distance) / 300;
          }
          miffy.dy += 0.02; // gravity
          miffy.x += miffy.dx;
          miffy.y += miffy.dy;
          // miffy.x += Math.random() * 0.5 - 0.25;

          //console.log(pointerRef.current)

          if (miffy.x > 100 || miffy.y > 100) {
            miffy.x = getStartingX();
            miffy.y = getStartingY();
            miffy.dx = getStartingVelocity();
            miffy.dy = getStartingVelocity();
          }
        })))
      );

      raf();
    });

    raf();
  }, []);

  const handlePointerMove = useCallback((e: React.PointerEvent) => {
    const x = e.clientX / e.currentTarget.clientWidth;
    const y = e.clientY / e.currentTarget.clientHeight;
    setMousePos({ x, y });

    pointerRef.current = {
      x,
      dx: e.movementX / e.currentTarget.clientWidth,
      y,
      dy: e.movementY / e.currentTarget.clientHeight,
    };
  }, []);

  return (
    <div ref={containerRef} onPointerMove={handlePointerMove} style={{ boxSizing: 'border-box', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: 'white', position: 'relative', cursor: 'none' }}>
      <div style={{ textAlign: 'center', padding: '1em' }}>
        <h1>🎉 Congratulations! 🎉</h1>
        <h2>You have completed the game!</h2>
        <h3>Thank you for playing!</h3>
        <a href={enableInputs ? 'https://mifi.no/thanks/' : undefined} style={{ fontSize: '4vmin', color: 'black', textDecorationStyle: 'wavy' }}>mifi.no</a>
        <div style={{ margin: '1em', color: 'rgb(255,50,100)', fontSize: '3em', opacity: enableInputs ? undefined : 0.3 }}>
          <button disabled={!enableInputs} style={{ all: 'unset', fontWeight: 'bold' }} onClick={onStartAgainClick}>Play again</button>
        </div>
      </div>

      {miffys.map((miffy, i) => (
        <Miffy key={i} miffy={miffy} />
      ))}

      {mousePos != null && (
        <div style={{ position: 'absolute', pointerEvents: 'none', left: `${mousePos.x * 100}%`, top: `${mousePos.y * 100}%`, fontSize: '5vmin', marginLeft: '-.5em', transform: 'rotate(180deg)' }}>
          🧲
        </div>
      )}
    </div>
  );
}

const musicPlayer = makeMusicPlayer();

export default function App() {
  const [gameStarted, setGameStarted] = useState(autoStart);
  const [completed, setCompleted] = useState(() => new URLSearchParams(window.location.search).has('completed'));

  const handleStart = useCallback(() => {
    setGameStarted(true);
  }, []);

  const onGameCompleted = useCallback(() => {
    setCompleted(true);
  }, []);

  useEffect(() => {
    function handlePointerDown() {
      if (musicPlayer.music.paused) musicPlayer.music.play();
    }
    document.addEventListener('pointerdown', handlePointerDown);
    return () => {
      document.removeEventListener('pointerdown', handlePointerDown);
    }
  }, [])

  const handleStartAgainClick = useCallback(() => {
    resetState();
    setCompleted(false);
  }, []);

  if (completed) {
    return <Completed onStartAgainClick={handleStartAgainClick} />;
  }

  if (gameStarted) return <Game onGameCompleted={onGameCompleted} muteMusicFor={musicPlayer.muteMusicFor} />;

  return <Home onStart={handleStart} />
}
