import invariant from 'tiny-invariant';
import { Draft, produce, WritableDraft } from 'immer'
import { nanoid } from 'nanoid'

import createAudioPlayer from './audioPlayer';
import allEmojis from './emojis';
import * as sounds from './audio';
import maoImg from './mao.png'
import winnieImg from './winnie.png'
import miffyImg from './miffy.svg';


interface GameObjectBase {
  id: string,
  x: number,
  y: number,
  dy: number,
  collected?: true,
  ended?: true,
}

type GameObjectSpecificProps = {
  type: 'plain' | 'boss',
  health: number,
  maxHealth: number,
} | {
  type: 'poison',
} | {
  type: 'bomb',
} | {
  type: 'mao',
}

type GameObject = GameObjectBase & GameObjectSpecificProps;

export interface GameState {
  objects: GameObject[],
  wind: number,
  numBombs: number,
  numPoints: number,
  levelAt: number,
  finishLevelAt: number,
  spaceship: {
    x: number,
    y: number,
    shooting: boolean,
    projectiles: {
      id: string,
      x: number,
      y: number,
      dx: number,
      dy: number,
    }[]
  },
}

export type PersistedGameState = Pick<GameState, 'numBombs' | 'numPoints' | 'levelAt' | 'finishLevelAt'>;

export default function makeGame({
  gameNode,
  hud,
  styles,
  initialState = {
    numBombs: 0,
    numPoints: 0,
    levelAt: 0,
    finishLevelAt: 50,
  },
  onStateChange,
  onGameCompleted,
  muteMusicFor,
}: {
  gameNode: HTMLElement,
  hud: HTMLElement,
  styles: CSSModuleClasses,
  initialState: PersistedGameState | undefined,
  onStateChange: (state: GameState) => void,
  onGameCompleted: () => void,
  muteMusicFor: (d: number) => void,
}) {
  const audioPlayer = createAudioPlayer();

  audioPlayer.loadAll(sounds);

  const maxLevel = 10;
  const categories = ['Smileys & Emotion', 'Animals & Nature', 'Food & Drink', 'Travel & Places', 'Activities', 'Flags'] as const;

  const bombEmoji = '💣';
  const poisonEmojis = ['💀', '☠️']
  const specialEmojis = new Set([bombEmoji, ...poisonEmojis, '☠']); // excluded from normal emojis

  const objectNodes: Record<string, HTMLElement> = {};
  const getNode = (id: string) => objectNodes[id];

  let projectileNodes: Record<string, HTMLElement> = {};
  const getProjectileNode = (id: string) => projectileNodes[id];

  const state: { current: GameState } = {
    current: {
      ...initialState,
      objects: [],
      spaceship: {
        x: 0.2,
        y: 0.8,
        shooting: false,
        projectiles: [],
      },
      wind: 0,
    },
  }

  let levelAdvancementTriggered: number | undefined;

  const updateState = (fn: (s: Draft<GameState>) => void) => {
    const nextState = produce(state.current, fn);
    state.current = nextState;
    onStateChange(nextState);
    return nextState;
  };

  const updateObject = (({ id }: Pick<GameObject, 'id'>, fn: (o: WritableDraft<GameObject>) => void) => (
    updateState((s) => {
      const index = s.objects.findIndex((o) => o.id === id);
      fn(s.objects[index]!);
    })
  ));

  const updateNumPoints = (diff: number) => updateState((s) => {
    s.numPoints += diff;
    if (s.numPoints >= s.finishLevelAt) s.numPoints = s.finishLevelAt;
    if (s.numPoints < 0) s.numPoints = 0;
  });

  const getPoisonEmoji = () => poisonEmojis[Math.floor(Math.random() * poisonEmojis.length)]!;

  function playSound(url: string, volume?: number) {
    audioPlayer.play(url, { volume });
  }

  const canvas = document.querySelector('#canvas') as HTMLElement;

  const spaceship = document.createElement('div');
  spaceship.classList.add(styles['spaceship']!);
  spaceship.innerText = '✈️'; // todo
  canvas.appendChild(spaceship);

  const calcXy = (n: number) => `${n * 100}%`;

  const animateNodeIn = (node: HTMLElement, duration = 200) => node.animate([
    { opacity: 0 },
    { opacity: 1 },
  ], {
    duration,
    easing: 'ease-in',
  });

  const animateSpecial = (node: HTMLElement, scaleAmplitude: number) => node.animate([
    {},
    { transform: `scale(${scaleAmplitude})`, filter: 'grayscale(1) brightness(150%) sepia(1) hue-rotate(320deg) saturate(6)', offset: 0.5 },
    {},
  ], {
    duration: 1000,
    easing: 'ease-in-out',
    iterations: Infinity
  });

  function spawnBomb() {
    const bombNode = addObject({ type: 'bomb' }, bombEmoji);
    animateSpecial(bombNode, 2);
  }

  function spawnPoison() {
    const node = addObject({ type: 'poison', dy: 0.5 }, getPoisonEmoji());
    animateSpecial(node, 0.7);
  }

  let gameEnded = false;
  let emojiSelection: string[];
  let rainbowAnimation: Animation | undefined;

  function initLevel() {
    if (state.current.levelAt >= maxLevel) {
      gameEnded = true;
      onGameCompleted();
    } else {
      rainbowAnimation?.cancel();
      rainbowAnimation = gameNode.animate([
        { backgroundPosition: '50% 200%' },
        { backgroundPosition: '50% 0%' },
      ], { duration: Math.max(2000, 6000 - (state.current.levelAt * 1000)), iterations: Infinity, easing: 'linear' });

      const category = categories[Math.floor(Math.random() * categories.length)]!;
      const categoryEmojis = allEmojis[category];
      emojiSelection = Object.values(categoryEmojis).flat().filter((emoji) => !specialEmojis.has(emoji));

      Array.from({ length: Math.min(state.current.levelAt + 3, 6) }).forEach(() => {
        spawnPlain();
      })
    }
  }

  function spawnPlain() {
    const emoji = emojiSelection[Math.floor(Math.random() * emojiSelection.length)];
    invariant(emoji != null);

    const health = state.current.levelAt === 0
      ? 100
      : Math.min(400, 100 + Math.random() * (100 * state.current.levelAt));

    addObject({
      type: 'plain',
      health,
      maxHealth: health,
    }, emoji);
  }

  function spawnBoss() {
    const health = 1000 + (Math.random() * 300 * state.current.levelAt);
    const node = document.createElement('img');

    node.src = miffyImg;
    node.style.width = '1em';
    node.style.pointerEvents = 'none';
    node.style.filter = 'grayscale(1) sepia(1) brightness(0.9) saturate(20)';
    node.animate([{ filter: 'grayscale(1) sepia(1) brightness(0.9) saturate(20) hue-rotate(360deg)' }], { duration: 3000, iterations: Infinity });
    node.animate([
      { transform: 'rotate(-10deg) scaleY(1.2)', offset: 0.2 },
      { transform: 'rotate(10deg) scaleY(1.2)', offset: 0.8 },
    ], { duration: 1000, iterations: Infinity, easing: 'ease-in-out' });
    addObject({
      type: 'boss',
      health,
      maxHealth: health,
      y: -0.1,
      dy: 0.2,
      size: 20,
    }, node);

    playSound('miffy');
    muteMusicFor(1.5);
  }

  function spawnSocialCredit() {
    const node = document.createElement('img');

    const isMao = Math.random() > 0.5;

    if (audioPlayer.play('mao', { maxConcurrency: 1 })) {
      muteMusicFor(13);
    }

    node.src = isMao ? maoImg : winnieImg;
    node.style.width = '1em';
    node.style.pointerEvents = 'none';
    if (isMao) node.style.borderRadius = '50%';
    animateSpecial(node, 1.1);

    addObject({
      type: 'mao',
      y: -0.1,
      dy: 0.5,
      size: 15,
    }, node);
  }

  function maybeSpawnRandoms({ isBombed }: { isBombed: boolean }) {
    if (!isBombed && Math.random() < 0.04) {
      spawnBomb();
    } else if (state.current.levelAt >= 1 && Math.random() < 0.01) {
      spawnPoison();
    } else if (state.current.levelAt >= 3 && Math.random() < 0.02) {
      spawnBoss();
    } else if (state.current.levelAt >= 2 && Math.random() < 0.03) {
      spawnSocialCredit();
    }
  }

  async function showAlert(text: string, { duration = 5, color = 'rgba(255, 0, 0)' }: { duration?: number, color?: string } = {}) {
    const alert = document.createElement('div');
    alert.innerText = text;
    alert.classList.add(styles['alert']!);
    alert.style.transform = 'scale(0)';
    alert.style.color = color;
    gameNode.appendChild(alert);
    await alert.animate([{ transform: 'scale(1)' }], { duration: 100 }).finished;
    const animate = alert.animate([{
      offset: 0,
      transform: 'scale(1)'
    },
    {
      offset: 0.5,
      transform: 'scale(1.3)',
      filter: 'brightness(5)',
    },
    {
      offset: 1,
      transform: 'scale(1)',
    }], { duration: 1000, iterations: Infinity });

    setTimeout(async () => {
      animate.cancel();
      alert.style.transform = 'scale(1)';
      await alert.animate([{ transform: 'scale(0)' }], { duration: 200 }).finished;
      gameNode.removeChild(alert);
    }, duration * 1000);
  }

  function damageObject(object: GameObject, isBombed: boolean, damage = 100) {
    const node = getNode(object.id);
    if (!node) return;

    if ('health' in object) {
      let newHealth = object.health - damage;
      if (newHealth < 0) newHealth = 0;
      const howDead = (object.maxHealth - newHealth) / object.maxHealth;
      node.style.transition = 'filter 0.5s, transform 0.5s';
      node.style.transform = `scale(${howDead * 0.5 + 1}) skew(${(Math.random() > 0.5 ? 1  : -1) * (Math.round((10 * howDead) + Math.random() * 10))}deg)`;
      node.style.filter = `grayscale(1) sepia(1) hue-rotate(320deg) saturate(${6 * howDead})`;

      updateObject(object, (o) => {
        invariant('health' in o);
        o.health = newHealth;
      });

      if (newHealth > 0) return;
    }

    if (object.collected) return;

    // Collecting:

    playSound(`die${Math.floor(Math.random() * 4) + 1}`, 1.5)

    updateObject(object, (o) => {
      o.collected = true
    });

    removeObject(object);

    node.style.pointerEvents = 'none';

    node.addEventListener('transitionend', () => {
      removeObjectNode(object.id);
    }, { once: true })

    node.style.transition = 'transform 0.8s, opacity 0.8s, filter 0.8s';
    node.style.transform = `scale(${Math.random() * 4 + 2}) skew(${-30 + Math.random() * 60}deg) rotate(${-30 + Math.random() * 60}deg)`;
    const filters = [`hue-rotate(${(Math.random() * 300) + 30}deg)`, 'brightness(3)'];
    node.style.filter = `${filters[Math.floor(Math.random() * filters.length)]}`;
    node.style.opacity = '0';

    if (object.type === 'plain' || object.type === 'boss') {
      hud.querySelector('.star')?.animate([
        {},
        { transform: `scale(${Math.min(5, 1.3 * (object.maxHealth / 100))})`, filter: 'brightness(3)', offset: 0.5 },
        {},
      ], { duration: 500, easing: 'ease-in-out' });

      const points = 'health' in object ? Math.round(object.maxHealth / 100) : 1;
      if (points > 1 && object.type === 'boss') showAlert(`+${points}✨`, { duration: 1, color: 'rgb(255, 255, 0)' });
      updateNumPoints(points);

      if (object.type === 'plain') spawnPlain();
      maybeSpawnRandoms({ isBombed });
    } else if (object.type === 'bomb') {
      playSound('hail');
      showAlert('💣 +1 bomb!', { duration: 1.5, color: 'rgb(0, 255, 0)' });

      updateState((state) => {
        state.numBombs++
      });
    } else if (object.type === 'mao') {
      audioPlayer.play('socialcredit');
      const points = -Math.floor(state.current.finishLevelAt * 0.2)
      showAlert(`🚨 ${points} social credit 👎`, { duration: 3 });
      updateNumPoints(points);
    } else if (object.type === 'poison') {
      playSound('bruh');
      showAlert('💀-1 level', { duration: 5 });
      levelAdvancementTriggered = -1;
    }

    if (state.current.numPoints >= state.current.finishLevelAt) {
      levelAdvancementTriggered = 1;
    }
  }

  function showDamageIndicator(x: string, y: string, hitObject = true) {
    const clickNode = document.createElement('div');
    clickNode.innerText = hitObject ? '🌟' : '💥';
    clickNode.classList.add(styles['point-star']!);

    const size = hitObject ? 20 : Math.random() * 2 + 3;
    clickNode.style.fontSize = `${size}vmin`;
    clickNode.style.marginLeft = `-${size/2}vmin`;
    clickNode.style.marginTop = `-${size/2}vmin`;

    clickNode.style.left = x;
    clickNode.style.top = y;

    clickNode.animate([
      { transform: 'scale(0)', opacity: 0.3 },
      { transform: `scale(${Math.random() * 0.5 + 1.3})`, opacity: 1, offset: 0.5 },
      { transform: `scale(1) rotate(${((Math.random() * 100) + 100) * (Math.random() > 0.5 ? 1 : -1)}deg)`, opacity: 0 }
    ], {
      duration: Math.random() * 200 + 200,
      easing: 'ease-out',
    }).finished.then(() => {
      canvas.removeChild(clickNode);
    });

    canvas.appendChild(clickNode);
  }

  function handleTap(e: Event) {
    const hitObjectId = Object.entries(objectNodes).find(([, node]) => node === e.target)?.[0];
    const hitObject = state.current.objects.find((o) => o.id === hitObjectId);

    playSound('punch', hitObject ? 1 : 0.4);

    const rect = canvas.getBoundingClientRect();
    const x = (e as PointerEvent).clientX - rect.left;
    const y = (e as PointerEvent).clientY - rect.top;

    showDamageIndicator(String(x), String(y), !!hitObject);

    if (hitObject != null) {
      damageObject(hitObject, false);
    }
  }

  function moveShip(e: PointerEvent) {
    const x = e.clientX / canvas.clientWidth;
    const y = e.clientY / canvas.clientHeight;
    updateState((s) => {
      s.spaceship.x = x;
      s.spaceship.y = Math.max(0, y - 0.06);
    })
  }

  let firstPointerDown: number | undefined;

  function handlePointerDown(e: PointerEvent) {
    // console.log('down', e.pointerId, e.target)
    if (firstPointerDown == null) {
      firstPointerDown = e.pointerId
      updateState((s) => {
        s.spaceship.shooting = true;
      });
      moveShip(e);
    }

    if (firstPointerDown !== e.pointerId) {
      handleTap(e);
    }
  }

  function handlePointerUp(e: PointerEvent) {
    // console.log('leave', e.pointerId)
    if (firstPointerDown === e.pointerId) {
      firstPointerDown = undefined;
      updateState((s) => {
        s.spaceship.shooting = false;
      });
    }
  }

  function handlePointerMove(e: PointerEvent) {
    if (firstPointerDown == null || firstPointerDown === e.pointerId) {
      moveShip(e);
    }
  }

  // https://stackoverflow.com/questions/16613503/webkit-user-select-none-not-working
  gameNode.addEventListener('touchstart', (e) => e.preventDefault())
  gameNode.addEventListener('pointerdown', handlePointerDown);
  gameNode.addEventListener('pointercancel', handlePointerUp); // pointercancel can be reproduced on iOS by holding the first finger down in the canvas, then tap-dragging to the left/right on the address bar
  // gameNode.addEventListener('pointerout', handlePointerUp);
  gameNode.addEventListener('pointerup', handlePointerUp);
  gameNode.addEventListener('pointermove', handlePointerMove);

  function triggerBomb() {
    playSound('explosion', 2);
    gameNode.animate([
      { filter: 'brightness(1)', offset: 0 },
      { filter: 'brightness(6)', offset: 0.1 },
      { filter: 'brightness(1)' },
      { filter: 'brightness(1)' },
    ], { duration: 1000 });

    const objectsInView = state.current.objects.filter((object) => {
      if (object.type === 'poison' || object.type === 'mao') return false;
      const rect = getNode(object.id)?.getBoundingClientRect();
      return rect && rect.top < window.innerHeight && rect.bottom >= 0;
    });

    objectsInView.forEach((o) => damageObject(o, true, 400));

    updateState((state) => {
      state.numBombs--
      if (state.numBombs < 0) state.numBombs = 0;
    });
  }

  function maybeTriggerBomb() {
    if (state.current.numBombs === 0) return;
    triggerBomb();
  }

  document.addEventListener('keydown', (e) => {
    if (e.key === ' ') {
      e.preventDefault();
      maybeTriggerBomb();
    }
    // cheat
    if (e.ctrlKey) {
      if (e.key === '1') {
        triggerBomb();
      }
      if (e.key === '2') {
        spawnPlain();
      }
      if (e.key === '3') {
        spawnBomb();
      }
      if (e.key === '4') {
        spawnBoss();
      }
      if (e.key === '5') {
        spawnSocialCredit();
      }
      if (e.key === '6') {
        spawnPoison();
      }
    }
  });

  const removeObjectNode = (id: GameObject['id']) => {
    const node = getNode(id);
    if (!node) return;
    canvas.removeChild(node);
    delete objectNodes[id];
  };

  function removeObject(object: Pick<GameObject, 'id'>) {
    updateState((s) => {
      const index = s.objects.findIndex(({ id }) => id === object.id)
      if (index !== -1) s.objects.splice(index, 1)
    });
  }

  function removeAllObjects() {
    updateState((s) => {
      s.objects.forEach((o) => {
        removeObjectNode(o.id);
      });
      s.objects = [];
    })
  }

  function removeAllProjectiles() {
    Object.values(projectileNodes).forEach((node) => canvas.removeChild(node));
    projectileNodes = {};
    updateState((s) => {
      s.spaceship.projectiles = [];
    })
  }

  function addObject({
    dy = 1,
    y = -0.5 + (Math.random() * 0.5),
    size = Math.random() * 7 + 10,
    ...extraState
  }: GameObjectSpecificProps & Partial<GameObjectBase> & { size?: number },
    content: string | Node
  ) {
    const node = document.createElement('div');

    const object = {
      id: nanoid(),
      x: 0.05 + (Math.random() * 0.9),
      y,
      dy: dy * ((Math.random() * 7) + 3) / 1000,
      ...extraState,
    };
    objectNodes[object.id] = node;
    updateState((state) => {
      state.objects.push(object);
    });

    node.append(content);
    node.classList.add(styles['game-object']!);
    node.style.fontSize = `${size}vmin`;
    node.style.marginLeft = `${-size / 2}vmin`;
    node.style.marginTop = `${-size / 2}vmin`;
    node.style.left = calcXy(object.x);
    node.style.top = calcXy(object.y);

    animateNodeIn(node);
    canvas.appendChild(node);
    return node;
  }

  initLevel();


  const startTime = Date.now();
  let lastProjectileTime = startTime;

  function tick() {
    const now = Date.now();
    const msSinceStart = now - startTime;
    const secSinceStart = msSinceStart / 1000;
    if (Math.floor(secSinceStart) % 5 === 0) {
      updateState((s) => {
        s.wind = (Math.random() * 2 - 1) / 1000;
      });
    }

    spaceship.style.left = calcXy(state.current.spaceship.x);
    spaceship.style.top = calcXy(state.current.spaceship.y);

    const maxProjectiles = 10;
    const projectileFreqMs = 150;

    updateState((s) => {
      if (s.spaceship.shooting && now - lastProjectileTime >= projectileFreqMs && s.spaceship.projectiles.length < maxProjectiles) {
        lastProjectileTime = now
        const node = document.createElement('div');

        const projectile = {
          id: nanoid(),
          x: s.spaceship.x,
          y: s.spaceship.y,
          dx: 0,
          dy: -0.02 + Math.random() * 0.005, // + s.spaceship.dy,
        };

        projectileNodes[projectile.id] = node;

        node.innerText = '🔥'; // todo
        node.classList.add(styles['projectile']!);
        const size = 4;
        node.style.fontSize = `${size}vmin`;
        node.style.marginLeft = `${-size / 2}vmin`;
        node.style.marginTop = `${-size / 2}vmin`;
    
        canvas.appendChild(node);
    
        s.spaceship.projectiles.push(projectile);
        playSound('laser');
      }

      s.spaceship.projectiles.forEach((projectile) => {
        const node = getProjectileNode(projectile.id);
        if (node == null) return;

        projectile.x += projectile.dx;
        projectile.y += projectile.dy;
        node.style.left = calcXy(projectile.x);
        node.style.top = calcXy(projectile.y);
      });

      s.objects.forEach((object) => {
        object.y += object.dy;
        object.x += state.current.wind;

        const node = getNode(object.id);

        if (object.y > 1) {
          if (object.type === 'poison' || object.type === 'bomb' || object.type === 'mao' || object.type === 'boss') {
            removeObjectNode(object.id);
            removeObject(object);
          } else if (!object.collected) {
            // re-spawn at top
            object.y = 0;
            if (node) animateNodeIn(node);
          }
        }
        if (object.x > 1) {
          object.x = 0;
          if (node) animateNodeIn(node);
        }
        if (object.x < 0) {
          object.x = 1;
          if (node) animateNodeIn(node);
        }
        if (node) {
          node.style.left = calcXy(object.x);
          node.style.top = calcXy(object.y);
        }
      });
    });

    function intersectsWithObject(o: GameObject, { x, y }: { x: number, y: number }) {
      const hitbox = 0.07; // todo?
      return (x > o.x - hitbox && x < o.x + hitbox && y > o.y - hitbox && y < o.y + hitbox)
    }

    const canHitWithProjectile = (o: GameObject) => o.type === 'plain' || o.type === 'boss';

    const maxObjectsSearch = 100; // for performance. todo not sure if needed
    state.current.objects.filter((o) => !canHitWithProjectile(o)).slice(0, maxObjectsSearch).find((o) => {
      if (intersectsWithObject(o, state.current.spaceship)) {
        damageObject(o, false);
      }
    });

    state.current.spaceship.projectiles.forEach((projectile) => {
      const node = getProjectileNode(projectile.id);

      function removeProjectile() {
        if (node == null) return;
        canvas.removeChild(node);
        delete projectileNodes[projectile.id]; 
        updateState((s) => {
          const index = s.spaceship.projectiles.findIndex(({ id }) => id === projectile.id);     
          if (index !== -1) s.spaceship.projectiles.splice(index, 1);
        });
      }

      if (projectile.x < 0 || projectile.x > 1 || projectile.y < 0 || projectile.y > 1) {
        removeProjectile();
      } else {
        // check if projectile hit any object. if so, remove it and damage
        state.current.objects.filter((o) => canHitWithProjectile(o)).slice(0, maxObjectsSearch).find((o) => {
          const projectileSize = 0.01;
          const pCenterX = projectile.x + (projectileSize / 2); // todo?
          const pCenterY = projectile.y + (projectileSize / 2);
          if (intersectsWithObject(o, { x: pCenterX, y: pCenterY })) {
            // console.log('hit', projectile.id)
            removeProjectile();
            showDamageIndicator(calcXy(projectile.x), calcXy(projectile.y));
            damageObject(o, false);
            playSound(o.type === 'boss' ? 'hitBoss1' : 'hit', 1);
            return true;
          }
          return false;
        })
      }
    });
  }

  const raf = () => {
    window.requestAnimationFrame(async () => {
      if (gameEnded) return;

      tick();

      if (levelAdvancementTriggered != null) {
        try {
          removeAllObjects();
          removeAllProjectiles();
          updateState((state) => {
            state.numPoints = 0;
            state.levelAt += levelAdvancementTriggered!;
            if (state.levelAt < 0) state.levelAt = 0;
            state.finishLevelAt = 50 + (state.levelAt * 25);
          })
          if (levelAdvancementTriggered > 0) {
            playSound('wow');
          }
      
          const fadeToWhite = 'contrast(0.5) brightness(3)';
          await gameNode.animate([{ filter: '' }, { filter: fadeToWhite, offset: 0.4 }, { filter: fadeToWhite, offset: 0.6 }, { filter: '' }], { duration: 4000 }).finished;
          initLevel();
        } finally {
          levelAdvancementTriggered = undefined;
        }
      }

      raf();
    });
  }

  raf();

  function cleanup() {
    gameEnded = true;
    audioPlayer.cleanup();
  }

  return {
    triggerBomb: maybeTriggerBomb,
    cleanup,
  }
}
