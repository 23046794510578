import musicFile from './music.m4a';


const musicVolume = 0.8;

export default function makeMusicPlayer() {
  const music = new Audio(musicFile)
  music.loop = true;
  music.volume = musicVolume;
  music.play().catch(() => undefined);

  let muteRampInterval: number | undefined;
  let muteTimeout: number | undefined;

  function muteMusicFor(sec: number) {
    if (muteRampInterval) clearInterval(muteRampInterval);
    clearTimeout(muteTimeout);
    music.volume = musicVolume;
    muteRampInterval = setInterval(() => {
      music.volume -= 0.1;
      const mutedVolume = 0.1
      if (music.volume < mutedVolume) {
        clearInterval(muteRampInterval);
        music.volume = mutedVolume;
        muteTimeout = setTimeout(() => {
          music.volume = musicVolume;
        }, sec * 1000);
      }
    }, 100);
  }

  return {
    music, muteMusicFor,
  }
}