import throttle from 'lodash/throttle';
import { PersistedGameState } from './makeGame';


const localStorageKey = 'gameState';
export const throttledSaveState = throttle((state: PersistedGameState) => {
  localStorage.setItem(localStorageKey, JSON.stringify(state));
}, 1000);

export function loadState() {
  try {
    return JSON.parse(localStorage.getItem(localStorageKey) ?? '') as PersistedGameState;
  } catch {
    return undefined
  }
}

export function resetState() {
  throttledSaveState.cancel();
  localStorage.removeItem(localStorageKey);
}
