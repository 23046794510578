export default function createAudioPlayer() {
  const audioContext = new AudioContext();

  const sounds = new Map<string, AudioBuffer>();
  const playingSounds = new Map<string, number>();

  async function load(name: string, url: string) {
    const response = await new Promise<ArrayBuffer>((resolve, reject) => {
      const request = new XMLHttpRequest();

      request.open('GET', url, true);
      request.responseType = 'arraybuffer';

      request.onload = () => resolve(request.response)

      request.onerror = (err) => reject(err);

      request.send();
    });

    const buffer = await audioContext.decodeAudioData(response);
    sounds.set(name, buffer);
  }

  async function loadAll(files: Record<string, string>) {
    await Promise.all(Object.entries(files).map(([name, url]) => load(name, url)));
  }

  const sources: Set<AudioBufferSourceNode> = new Set();

  function play(name: string, { volume = 1, time = 0, maxConcurrency }: { volume?: number | undefined, time?: number | undefined, maxConcurrency?: number | undefined } = {}) {
    const buffer = sounds.get(name);
    if (!buffer) {
      console.error(`Sound ${name} not loaded`);
      return false;
    }

    const numPlaying = playingSounds.get(name) ?? 0;
    if (maxConcurrency != null && numPlaying >= maxConcurrency) {
      return false;
    }
    playingSounds.set(name, (numPlaying) + 1);

    const source = audioContext.createBufferSource();
    source.buffer = buffer;

    sources.add(source);

    source.onended = () => {
      playingSounds.set(name, (playingSounds.get(name)! - 1));
      source.stop(0);
      sources.delete(source);
    };

    const gainNode = audioContext.createGain();
    gainNode.gain.value = volume;

    source.connect(gainNode).connect(audioContext.destination);

    source.start(time);
    return true;
  }

  function stopAll() {
    sources.forEach((source) => source.stop(0));
  }

  async function cleanup() {
    stopAll();
    await audioContext.close();
  }

  return { play, loadAll, stopAll, cleanup };
}
